import type { BrowserConfig } from '@bugsnag/browser';
import Bugsnag, { type NotifiableError } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../package.json');

/**
 * Initializes the Bugsnag error reporting
 *
 * Dashboard:
 * @see https://dashboard-bugsnag.netrtl.com/cbc-cologne-broadcasting-center-gmbh/rtlde-slash-maeven-slash-channel-web/errors?sort=last_seen
 */
export const initErrorReporting = () => {
  const config: BrowserConfig = {
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY ?? '',
    plugins: [new BugsnagPluginReact()],
    appVersion: version,
    appType: typeof window !== 'undefined' ? 'browser' : 'server',
    endpoints: {
      notify: process.env.NEXT_PUBLIC_BUGSNAG_ENDPOINT_NOTIFY ?? '',
      sessions: process.env.NEXT_PUBLIC_BUGSNAG_ENDPOINT_SESSIONS ?? '',
    },
    //enabledReleaseStages: ['local', 'dev', 'sandbox', 'preprod', 'prod'],
    releaseStage: process.env.NEXT_PUBLIC_DEPLOYMENT_STAGE,
    // Do not track sessions in Bugsnag
    autoTrackSessions: false,
    // Do not collect user IPs
    collectUserIp: false,
  };

  config.onError = (error) => {
    const mostRecentError = error.errors[0];
    if (
      mostRecentError.errorMessage.includes('Minified React error #418') ||
      mostRecentError.errorMessage.includes('Minified React error #422') ||
      mostRecentError.errorMessage.includes('Minified React error #423') ||
      mostRecentError.errorMessage.includes('Minified React error #425') ||
      mostRecentError.errorMessage.includes('NEXT_NOT_FOUND')
    ) {
      return false;
    }

    if (
      mostRecentError.stacktrace.some(
        ({ file }: { file?: string }) => file?.includes('https://www.rtl.de/phoenix/mbundle')
      )
    ) {
      return false;
    }

    if (process.env.NEXT_PUBLIC_DEPLOYMENT_STAGE === 'local') {
      console.info('*** send bug to Bugsnag ***', error.errors);
    }
  };

  if (!Bugsnag.isStarted()) {
    Bugsnag.start(config);
  }
};

/**
 * Reports errors to Bugsnag dashboard
 */
export const logError = (error: NotifiableError, metadata?: { [key: string]: unknown }) => {
  Bugsnag.notify(error, (event) => {
    if (metadata) {
      event.addMetadata('CUSTOM', metadata);
    }
  });
};
