'use client';
import { createContext, useEffect, useState, type PropsWithChildren } from 'react';
import type { UserConsent, UserConsentContext, CustomVendor } from '../../../types/consent';
import { listenForConsent } from './tcf';

const EmptyUserConsent: UserConsent = {
  tcString: '',
  consentedPurposes: [],
  consentedVendors: [],
  grants: {},
};

export const ConsentContext = createContext<UserConsentContext>({
  consent: {
    ...EmptyUserConsent,
  },
  // eslint-disable-next-line
  setConsent: () => {},
});

export type ConsentProviderProps = PropsWithChildren<{}>;

export const ConsentProvider = ({ children }: ConsentProviderProps) => {
  const [consent, setConsent] = useState<UserConsent>({ ...EmptyUserConsent });

  useEffect(() => listenForConsent(setConsent), []);

  return (
    <ConsentContext.Provider
      value={{
        consent,
        setConsent,
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};

export const hasVendorConsent = (vendorId: string, consentedVendors?: Array<CustomVendor>): boolean => {
  if (Array.isArray(consentedVendors)) {
    return consentedVendors.some((consentedVendor) => consentedVendor._id === vendorId);
  }
  return false;
};
